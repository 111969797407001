<template>
<div class="box">
  <div v-for="(item,index) in distype" :key="index">
    <dl class="textList">
      <dt style="margin-bottom: 10px">
        <a >
            {{item.title}}
          </a>
      </dt>
      <dd>
        <a @click="itemclick(i, option.i,item.title, option.index)" v-for="(option,i) in item.list" :key="i" style="width: 70px">
          {{option.name}}
        </a>
      </dd>
    </dl>
  </div>
  <a  class="morea" @click="tomore"><span>更多疾病></span></a>
</div>
</template>

<script>
export default {
  name: 'ChooseDisnow',
  data () {
    return {
      distype: [
        {
          title: '皮肤性病科',
          // 男性型脱发 皮疹  神经性皮炎 狐臭 风疹
          list: [
            {
              i: '112',
              name: '男性型秃发'
            },
            {
              i: '252',
              name: '皮疹'
            },
            {
              i: '124',
              name: '神经性皮炎'
            },
            {
              i: '6105',
              name: '狐臭'
            },
            {
              i: '2932',
              name: '风疹'
            },
            {
              i: '皮肤科',
              index: 5,
              name: '更多'
            }
          ]
        },
        {
          title: '男科',
          // 早泄 阳痿 包茎 包皮炎 不育
          list: [
            {
              i: '15887',
              name: '早泄'
            },
            {
              i: '16097',
              name: '阳痿'
            },
            {
              i: '15892',
              name: '包茎'
            },
            {
              i: '16779',
              name: '包皮炎'
            },
            {
              i: '14654',
              name: '不育'
            },
            {
              i: '男科',
              index: 4,
              name: '更多'
            }
          ]
        },
        {
          title: '妇产科',
          // 不孕 子宫肌瘤 宫颈癌 卵巢囊肿  先兆流产
          list: [
            {
              i: '6521',
              name: '不孕'
            },
            {
              i: '8290',
              name: '子宫肌瘤'
            },
            {
              i: '8395',
              name: '宫颈炎'
            },
            {
              i: '7289',
              name: '卵巢囊肿'
            },
            {
              i: '7006',
              name: '先兆流产'
            },
            {
              i: '妇科',
              index: 2,
              name: '更多'
            }
          ]
        },
        {
          title: '肿瘤科',
          // 乳腺癌 肝癌 胃癌 结肠癌 鼻咽癌
          list: [
            {
              i: '6681',
              name: '乳腺癌'
            },
            {
              i: '11649',
              name: '肝癌'
            },
            {
              i: '12214',
              name: '胃癌'
            },
            {
              i: '11330',
              name: '结肠癌'
            },
            {
              i: '14186',
              name: '鼻咽癌'
            },
            {
              i: '肿瘤内科',
              index: 7,
              name: '更多'
            }
          ]
        }
      ]
    }
  },
  methods: {
    itemclick (i, e, g, j) {
      // this.$router.push('/diseasedetail/' + 1)
      // // sessionStorage.setItem('nav', '1')
      if (i < 5) {
        this.$router.push({ path: 'disease/diseasedetail', query: { i: e } })
      } else {
        this.$router.push({ path: 'disease', query: { dempFirst: e, dempSecond: g, index: j, eindex: 0 } })
      }
    },
    tomore () {
      this.$router.push('/disease/')
    }
  }
}
</script>

<style scoped>
.box{
    /* margin: 30px 0 25px; */
    /* background: #f7f7f7; */
    height: 120px;
    padding: 18px 0 15px;
    overflow: hidden;
    margin: 0 auto;
}
dl {
    float: left;
    margin-left: -1px;
    width: 234px;
    height: 110px;
    padding: 0 6px 0 12px;
    overflow: hidden;
    border-right: 1px solid #D9D9D9;
}
dl dt {
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    overflow: hidden;
    text-align: center;
}
dl dd {
    height: 72px;
    line-height: 24px;
    overflow: hidden;
    padding-top: 2px;
    font-size: 12px;
}
dl dd a {
    display: inline-block;
    padding-right: 24px;
    font-size: 14px;
}
a {
  cursor: pointer;
}
</style>
