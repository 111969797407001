<template>
  <div class="videoss">
    <small-videos-list-item class="now" v-for="(item, index) in videos" :videos-item="item" :videos-index="index" :key="item.title" :type='type' :stype='stype'></small-videos-list-item>
  </div>
</template>

<script>
import SmallVideosListItem from './SmallVideosListItem'
export default {
  name: 'VideosList',
  components: {
    SmallVideosListItem
  },
  props: {
    videos: {
      type: Array,
      default () {
        return []
      }
    },
    type: {
      type: String
    },
    stype: {
      type: String
    }
  }
}
</script>

<style scoped>
.videoss{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 2px;
}
.videoss :hover{
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1);
}
.now :hover{
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(0, 0, 0, 0);
}
</style>
