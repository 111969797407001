<template>
  <div>
    <div class="topbox">
          <div class="topvideo">
            <el-carousel height="492px">
              <el-carousel-item v-for="(item, index) in bannerList" :key="index" @click.native="topage(item.h5Url)">
                <h3 class="small">
                  <img :src="item.bannerImg" width="100%" height="100%" alt />
                </h3>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="zzbox">
              <div class="ztitle"><span>
                <img :src="msg.title" alt=""></span></div>
              <small-videos-list :videos="videos" class="smallvideo" :stype="'tvideo'"></small-videos-list>
          </div>
    </div>
        <!-- <choose-dis-type :distype="type" class="choose"></choose-dis-type> -->
        <!-- <choose-disnow class="choose" :distype="type"></choose-disnow> -->
        <choose-disnow class="choose"></choose-disnow>
        <div class="biaoti">
          <recommend-title :Title="msg.tjtitle" :eTitle="msg.etitle" :color="color" @paixu="paixu" :xianshi="'true'" class="rectitle"></recommend-title>
        </div>
        <div class="msbox">
            <!-- <videos-list :videos="videoss" class="yyvideo" :type="'video'" :stype="'tvideo'"></videos-list> -->
            <el-row class="row">
              <div class="hh">
                <el-col :span="25" v-for="project in docaudios" :key="project.infoId" :offset="1">
                    <videos-list-itemtest :videos-item="project" class="now"></videos-list-itemtest>
                </el-col>
              </div>
            </el-row>
        </div>
        <bottom-bar class="bottom"></bottom-bar>
  </div>
</template>

<script>
import SmallVideosList from 'components/content/videos/SmallVideosList'
import BottomBar from 'components/content/bottombar/BottomBar'
// import ChooseDisType from 'components/content/choose/ChooseDisType'
import ChooseDisnow from 'components/common/choosedis/ChooseDisnow'
import RecommendTitle from 'components/content/recommendtitle/RecommendTitle'
// import VideosList from 'components/content/videos/VideosList'
import VideosListItemtest from 'components/content/videos/VideosListItemtest'
// 获取视频列表
import { getMediaInfoList, getBanner } from 'network/getData'
export default {
  name: 'comViews',
  components: {
    SmallVideosList,
    // ChooseDisType,
    RecommendTitle,
    // VideosList,
    VideosListItemtest,
    ChooseDisnow,
    BottomBar
  },
  props: {
    msg: {
      type: Object
    },
    type: {
      type: String
    }
  },
  data () {
    return {
      color: '',
      docaudios: [],
      bannerList: [],
      videos: [],
      types: ['latest', 'popular']
    }
  },
  methods: {
    paixu (val) {
      // // console.log(val)
      this.getMediaInfoListdata(this.types[val], 20, 1, this.type)
    },
    async getMediaInfoListdata (filterCondition, row, page, type) {
      try {
        const result = await getMediaInfoList(filterCondition, row, page, type)
        this.docaudios = result.data.data
      } catch (error) {
        // console.log(error)
      }
    },
    topage (e) {
      const asd = e.split('|')
      if (asd[1] === 'video') {
        // // sessionStorage.setItem('nav', '3')
        // sessionStorage.setItem('id', asd[0])
        // this.$router.push({ path: '/mobVideoCenterDel' })
        this.$router.push({ path: '/mobVideoCenterDel', query: { videoId: asd[0] } })
      } else if (asd[1] === 'audio') {
        // // sessionStorage.setItem('nav', '2')
        // sessionStorage.setItem('id', asd[0])
        // this.$router.push({ path: '/mobVideoCenterAudioDel' })
        this.$router.push({ path: '/mobVideoCenterAudioDel', query: { audioId: asd[0] } })
      } else if (asd[1] === 'article') {
        // // sessionStorage.setItem('nav', '4')
        // sessionStorage.setItem('id', asd[0])
        // this.$router.push({ path: '/mobVideoCenterArticleDel' })
        this.$router.push({ path: '/mobVideoCenterArticleDel', query: { articleId: asd[0] } })
      } else if (asd[1] === 'qa') {
        // // sessionStorage.setItem('nav', '5')
        // sessionStorage.setItem('id', asd[0])
        // this.$router.push({ path: '/mobQuestionCenterDel' })
        this.$router.push({ path: '/mobQuestionCenterDel', query: { qaId: asd[0] } })
      } else if (e === 'empty') {
        return ''
      } else {
        window.open(e)
      }
    },
    async getpopularMediaInfoListdata (filterCondition, row, page, type) {
      try {
        const result = await getMediaInfoList(filterCondition, row, page, type)
        this.videos = result.data.data
      } catch (error) {
        // console.log(error)
      }
    },
    async getBannerList (type) {
      try {
        const result = await getBanner(type)
        this.bannerList = result.data.data
        // console.log('this.bannerList', this.bannerList)
      } catch (error) {
        // console.log(error)
      }
    }
  },
  mounted () {
    this.color = this.msg.color
    this.getBannerList(this.type)
    this.getMediaInfoListdata('latest', 20, 1, this.type)
    this.getpopularMediaInfoListdata('popular', 6, 1, this.type)
  }
}
</script>

<style scoped>
@import url("~assets/css/cc.css");
.now{
  position: relative;
}
.span{
  width: 25%;
}
.el-col-offset-1{
  margin-left: 1%;
  margin-bottom: 1%;
}
.el-col :hover{
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1);
}
.now :hover{
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(0, 0, 0, 0);
}
.row{
  position: relative;
  top: 120px;
}
</style>
